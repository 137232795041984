<template>
  <div>
    <!-- <b-card title="Welcome"> -->
    <b-card>
      <b-card-title>
        Welcome
        <img src="/favicon.svg" alt="Custom Icon" />
      </b-card-title>
      <b-card-text>Welcome to Suwun Dashboard.</b-card-text>
    </b-card>
    <!-- </b-card> -->
  </div>
</template>

<script>
import {
  UsersIcon,
  FileTextIcon,
  StarIcon,
  InstagramIcon,
} from "vue-feather-icons";
import flatPickr from "vue-flatpickr-component";
import moment from "moment";
import VueApexCharts from "vue-apexcharts";
import _ from "lodash";
import {
  BCard,
  BCardText,
  BCardTitle,
  BLink,
  BRow,
  BCol,
  BSpinner,
} from "bootstrap-vue";
export default {
  components: {
    BCard,
    BCardTitle,
    BCardText,
    BLink,
    BRow,
    BCol,
    flatPickr,
    BSpinner,
    UsersIcon,
    FileTextIcon,
    StarIcon,
    InstagramIcon,
    apexcharts: VueApexCharts,
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
